const human_names = ['Lilly',  
'Samuel',  
'Parker',  
'Kay',  
'Barbra',  
'Jacinto',  
'Latrisha',  
'Beth',  
'Janay',  
'Dedra',  
'Jina', 
'Arlena',  
'Dyan',  
'Izola',  
'Elvira',  
'Leoma',  
'Lael',  
'Will',  
'Nu',  
'Eneida',  
'Darrel',  
'Charise',  
'Jacelyn',  
'Gladys',  
'Katy',  
'Katherina',  
'Molly',  
'Amie',  
'Mikki',  
'Judi',  
'Dorcas',  
'Janean',  
'Kendall',  
'Pauletta',  
'Jodi',  
'Aja',  
'Mimi',  
'Cecily',  
'Phylicia',  
'Reuben',  
'Josef',  
'Devona',  
'Perla',  
'Erin',  
'Tierra',  
'Benjamin',  
'Kena',  
'Branda',  
'Justine',  
'Tracey',  
'Dianna',  
'Reid',  
'Celina',  
'Delois',  
'Karey',  
'Dorcas',  
'Toccara',  
'Waldo',  
'Luis',  
'Clifton',  
'Elise',  
'Pamella',  
'Lavona',  
'Jewell',  
'Tamie',  
'Jeanett',  
'Wilburn',  
'Kaila',  
'Byron',  
'Leeann',  
'Esmeralda',  
'Dorotha',  
'Carisa',  
'Joni',  
'Verna',  
'Delisa',  
'Manual',  
'Pauline',  
'Amelia',  
'Geralyn',  
'Angeline',  
'Pansy',  
'Shakira',  
'Sheryl',  
'Shiela',  
'Regenia',  
'Aurore',  
'Demetrice',  
'Lovetta',  
'Maurine',  
'Roger',  
'Sunni',  
'Maddie',  
'Cory',  
'Nadia',  
'Mohamed',  
'Ashely',  
'Pearlene',  
'Allie',  
'Delicia']

const pet_names = [
    'Ace',
    'Apollo',
    'Bailey',
    'Bandit',
    'Baxter',
    'Bear',
    'Beau',
    'Benji',
    'Benny',
    'Bentley',
    'Blue',
    'Bo',
    'Boomer',
    'Brady',
    'Brody',
    'Bruno',
    'Brutus',
    'Bubba',
    'Buddy',
    'Buster',
    'Cash',
    'Champ',
    'Chance',
    'Lucy',
    'Lulu',
    'Luna',
    'Macy',
    'Maddie',
    'Madison',
    'Maggie',
    'Marley',
    'Maya',
    'Mia',
    'Millie',
    'Mimi',
    'Minnie',
    'Missy',
    'Misty',
    'Mocha',
    'Molly',
    'Nala',
    'Nikki',
    'Olive',
    'Peanut',
    'Pebbles',
    'Penny',
    'Pepper',
    'Phoebe',
    'Piper',
    'Princess',
    'Riley',
    'Rosie',
    'Roxie',
    'Roxy',
    'Ruby',
    'Sadie',
    'Sally',
    'Sandy',
    'Sasha',
    'Sassy',
    'Scout',
    'Shadow'
]

module.exports = { human_names, pet_names };